<template>
    <ReservePage />
</template>

<script>
import {
    mapActions
} from 'vuex'

import ReservePage from '../components/ReservePage.vue'

export default {
    data: () => ({
    }),
    components: {
        ReservePage
    },
    methods: {
    },
    mounted() {
    }
}
</script>

